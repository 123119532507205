<template>
  <div class="pd-20 bg">
    <a-page-header
      class="mb-0"
      style="padding: 0"
      sub-title="Detalhes do usuário"
      @back="() => $router.go(-1)"
    >
      <div slot="title">
        <h1>Usuário</h1>
      </div>
    </a-page-header>

    <a-row>
      <a-col class="mb-20" :span="24">
        <a-row type="flex" justify="start" :gutter="20">
          <a-col>
            <img
              v-if="userData.meta.avatar"
              :src="userData.meta.avatar"
              style="border-radius: 200px; width: 120px; height: 120px"
            />
            <a-avatar
              v-else
              :size="120"
              icon="user"
              style="
                backgroundcolor: #ddd;
                width: 120px;
                height: 120px;
                line-height: normal !important;
              "
            />
          </a-col>

          <a-col class="user-details">
            <div v-if="userData.first_name">
              <div class="name">
                <strong>
                  {{ userData.first_name }}
                  {{ userData.last_name }}
                </strong>
              </div>
              <div class="email">
                <a-icon type="mail" class="mr-10" />
                <i>{{ userData.email }}</i>
              </div>
              <div class="role mb-10">
                <a-icon type="user" class="mr-5" />
                {{ formatUserRole(userData.role) }}
              </div>
              <a-tag v-if="userData.status == 1" color="#87d068">
                {{ formatUserStatus(userData.status) }}
              </a-tag>
              <a-tag v-if="userData.status == 0" color="#cccccc">
                {{ formatUserStatus(userData.status) }}
              </a-tag>
            </div>
            <a-skeleton v-else />
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="24">
        <div class="pd-20" style="background: #fff">
          <a-tabs class="mt-0" default-active-key="1">
            <a-tab-pane key="1" tab="Histórico do usuário">
              <LogsTimelineV2
                class="mt-20"
                :module="'user'"
                :id="$route.params.id"
              />
            </a-tab-pane>
            <a-tab-pane key="2" tab="Histórico de alterações" force-render>
              <LogsTimelineV2
                class="mt-20"
                module=""
                :userId="$route.params.id"
              />
            </a-tab-pane>
          </a-tabs>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { format, parse } from "date-fns";
import LogsTimelineV2 from "@/components/logs/LogsTimelineV2.vue";

export default {
  components: { LogsTimelineV2 },
  name: "ViewUser",
  data() {
    return {
      userData: {
        modification_history: [],
        access_history: [],
        meta: {
          avatar: null,
        },
      },
    };
  },
  beforeMount() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.$http
        .post("/user/read_one", {
          id: this.$route.params.id,
        })
        .then(({ data }) => {
          this.userData = {
            ...data,
            modification_history: data.modification_history.reverse(),
            access_history: data.access_history.reverse(),
          };
          this.editUser = true;
          document.title = `Usuário - ${data.first_name} ${data.last_name}`;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatUserStatus(status) {
      return status == 1 ? "Ativo" : "Desativado";
    },
    formatDate(date) {
      let aaa = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(aaa, "dd/MM/yyyy HH:mm");
    },
    formatUserRole(role) {
      if (role == "admin") {
        role = "Administrador";
      }
      if (role == "user") {
        role = "Usuário";
      }
      if (role == "manager") {
        role = "Gestor";
      }
      if (role == "financial") {
        role = "Financeiro";
      }

      return role;
    },
  },
};
</script>
